/** @format */
import React, { Component } from "react";
import { useTranslation } from "react-i18next";
// import React, { useRef } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';


//page componets
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';

//import css
// import "./home.scss";

// images
import bannerimage from "../../assets/images/structure/faq-banner-img.png";

const Faq = () => {
  const { t } = useTranslation();

  return (
    <>
  
    <Header />
      <div className="main-content-wrapper">
        <section className="static-pages-banner">
          <img src={bannerimage} alt="Privacy Policy" className="bannerimage" />
        </section>
        <section className="static-content">
          <div className="container">
            <h1 className="h1 mb-5">{t('faq_title')}</h1>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t('faq_question_1')}</h4>
              <p className="p3 mb-3">{t('faq_answer_1')}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t('faq_question_2')}</h4>
              <p className="p3 mb-3">{t('faq_answer_2')}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t('faq_question_3')}</h4>
              <p className="p3 mb-3">{t('faq_answer_3')}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t('faq_question_4')}</h4>
              <p className="p3 mb-3">{t('faq_answer_4')}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t('faq_question_5')}</h4>
              <p className="p3 mb-3">{t('faq_answer_5')}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t('faq_question_6')}</h4>
              <p className="p3 mb-3">{t('faq_answer_6')}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t('faq_question_7')}</h4>
              <p className="p3 mb-3">{t('faq_answer_7')}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t('faq_question_8')}</h4>
              <p className="p3 mb-3">{t('faq_answer_8')}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t('faq_question_9')}</h4>
              <p className="p3 mb-3">{t('faq_answer_9')}</p>
            </div>

            <div className="mb-5">
            <h4 className="h4 mb-3">{t('faq_question_10')}</h4>
              <p className="p3 mb-3">{t('faq_answer_10')}</p>
            </div>
            <div className="mb-0">
              <h4 className="h4 mb-3">{t('faq_question_11')}</h4>
              <p className="p3 mb-3">{t('faq_answer_11')}</p>
            </div>
          </div>
        </section>
      </div>
    <Footer />
    </>
  );
};

export default Faq;
