import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { OverlayPanel } from "primereact/overlaypanel";

// images
import headerLogo from "../../../assets/images/logo/header-logo.svg";
import Phone from "../../../assets/images/structure/phone.svg";
import English from "../../../assets/images/structure/en.png";
import Arabic from "../../../assets/images/structure/arabic.png";
import "./header.scss";
import { useTranslation } from "react-i18next";

export default function Header() {
  const strings = require("../../../lang/en.json");
  const [showMenu, setShowMenu] = useState(false);
  const [langName, setLangName] = useState("English");
  const [langImg, setLangImg] = useState(English);
  const { i18n,t } = useTranslation();
  const navigate = useNavigate()
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };


  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    {
      label: "English",
      value: "English",
      image: "../../../assets/images/logo/header-logo.svg",
    },
    { label: "Arabic", value: "Arabic", image: "url_to_image_2" },
    // Add more options as needed
  ];

  const customDropdownTemplate = (option) => {
    return (
      <div className="custom-dropdown-option">
        <img src={option.image} alt={option.label} className="option-image" />
        <span>{option.label}</span>
      </div>
    );
  };

  const overlayPanelRef = useRef(null);
  const showDropdown = (event) => {
    overlayPanelRef.current.show(event);
  };

  const hideDropdown = () => {
    overlayPanelRef.current.hide();
  };
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    hideDropdown()
    setIconLang()
  };
  const setIconLang = () => {
    if (localStorage.getItem("i18nextLng") === "ar") {
      setLangName("Arabic");
      setLangImg(Arabic);
    } else {
      setLangName("English");
      setLangImg(English);
    }
  };
  useEffect(() => {
    setIconLang()
    const hashValue = window.location.hash.substring(1);
    if(hashValue){
      let scroll = document.getElementById(hashValue)
      scroll.scrollIntoView()
      }
  }, []);
  return (
    <>
      <div className="header-wrapper">
        <div className="header">
          <div className="container">
            <nav className="navbar">
              <div className="navbar-logo">
                <Link to="/" className="flex">
                  <img src={headerLogo} alt="logo" className="headerLogo" />
                </Link>
              </div>
              <ul className={showMenu ? "nav-links show" : "nav-links"}>
                <li className="hmenu">
                  <a className="hmenu-a" href="/#whatwedo">
                    {t("aboutUs")}
                  </a>
                </li>
                <li className="hmenu">
                  <a className="hmenu-a" href="/#categories">
                    {t("categories")}
                  </a>
                </li>
                {/* <li className="hmenu"><Link className="hmenu-a" to="/">Testimonials</Link></li> */}
                <li className="languageDropdown">
                  {/* <Dropdown
                  value={selectedOption}
                  options={options}
                  onChange={(e) => setSelectedOption(e.value)}
                  placeholder="English"
                  optionLabel="label"
                  itemTemplate={customDropdownTemplate}
                /> */}
                  <div className="custom-dropdown languageSelect">
                    <Button
                      className="languageSelectBtn"
                      onClick={(e) => showDropdown(e)}
                    >
                      <img src={langImg} alt="English" className="langImg" />{" "}
                      {langName}
                    </Button>
                    <OverlayPanel ref={overlayPanelRef} onHide={hideDropdown}>
                      <ul className="ddUl">
                        <li className="ddLi" onClick={() => changeLang("en")}>
                          <img
                            src={English}
                            alt="English"
                            className="langImg"
                          />{" "}
                          English
                        </li>
                        <li className="ddLi" onClick={() => changeLang("ar")}>
                          <img src={Arabic} alt="Arabic" className="langImg" />{" "}
                          Arabic
                        </li>
                      </ul>
                    </OverlayPanel>
                  </div>
                </li>
                <li>
                  <a href="/#contactus" style={{ textDecoration: "none" }}>
                    <Button className="hdrContactbtn" rounded size="small">
                      <img src={Phone} alt="Phone" className="mr-2" />
                      {t("contactUs")}
                    </Button>
                  </a>
                </li>
              </ul>
              <div className="menu-icon" onClick={toggleMenu}>
                <i className={showMenu ? "pi pi-times" : "pi pi-bars"}></i>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* <Outlet /> */}
    </>
  );
}
