/**
 * @file App.js
 * @description This component is used to show app.
 * @exports App component
 * @fileContains App component and routes of app some default widgets like snack bar and loader
 */

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.scss';
import './App.scss';
import './assets/scss/direction.scss';
import { useEffect } from "react";
import Home from "./pages/Home/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import TermsCondition from "./pages/TermsCondition/TermsCondition";
import Faq from "./pages/FAQ/Faq";
import './i18n';
import { useTranslation } from "react-i18next";
import DeleteAccountReq from "./pages/deleteAccountReq/deleteAccountReq";

const App = () => {

  const { i18n } = useTranslation();
  

  const changeLanguage = (lang) => {
    localStorage.setItem('i18nextLng', lang)
    i18n.changeLanguage(lang);
  }

  useEffect(() => {
    if (localStorage.getItem('i18nextLng')) {
      if (!['ar', 'en'].includes(localStorage.getItem('i18nextLng'))) {
        localStorage.setItem('i18nextLng', 'en')
        changeLanguage('en')
      }
      changeLanguage(localStorage.getItem('i18nextLng'))
    } else {
      localStorage.setItem('i18nextLng', 'en')
      changeLanguage('en')
    }
  }, [])

  

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/delete-account-request" element={<DeleteAccountReq/>}/>
          {/* <Route path="/" element={<Header />}>
            <Route path="/bundle-listing" element={<BundleListing />} />
          </Route> */}
        </Routes>
      </Router>

    </>
  );
};

export default App;