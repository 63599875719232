import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// images
import footerLogo from "../../../assets/images/logo/footer-logo.svg";
import facebook from "../../../assets/images/structure/facebook.svg";
import twitter from "../../../assets/images/structure/twitter.svg";
import insta from "../../../assets/images/structure/insta.svg";
import innovativeTadersLogo from "../../../assets/images/structure/Innovative-taders-logo-app.png";

// other
import "./footer.scss";
import { useTranslation } from "react-i18next";
export default function Footer() {
  const { t } = useTranslation()
  return (
    <>
      <div className="footer-section">
        <div className="text-center">
          <img src={footerLogo} alt="logo" className="ftrLogo" />
        </div>
        <div className="container">
          <ul className="footermenu flex justify-content-center mb-5 flex-wrap">
            <li className="footermenu-li">
              <Link to="/" className="ftrmenuLink">
                {t("home")}
              </Link>
            </li>
            <li className="footermenu-li">
              <a href="/#whatwedo" className="ftrmenuLink">
                {t("aboutUs")}
              </a>
            </li>
            <li className="footermenu-li">
              <a href="/#categories" className="ftrmenuLink">
                {t("categories")}
              </a>
            </li>
            {/* <li className="footermenu-li">
              <Link to="/" className="ftrmenuLink">{t("testimonials}</Link>
            </li> */}
            <li className="footermenu-li">
              <a href="/#contactus" className="ftrmenuLink">
                {t("contactUs")}
              </a>
            </li>
            <li className="footermenu-li">
              <Link to="/privacy-policy" className="ftrmenuLink">
                {t("privacyPolicy")}
              </Link>
            </li>
            <li className="footermenu-li">
              <Link to="/terms-condition" className="ftrmenuLink">
                {t("termsAndConditions")}
              </Link>
            </li>
            <li className="footermenu-li">
              <Link to="/faq" className="ftrmenuLink">
                {t("fAQ")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="container mb-5">
          <div style={{ textAlign: "center" }}>
            <p
              className="p3 mb-0"
              style={{
                color: "#ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {t("poductByInnovativeTraders")}{" "}
              <img
                src={innovativeTadersLogo}
                alt="clientlogo"
                className="productbylogo ml-3"
              />
            </p>
          </div>
        </div>
        <div className="footer-social-media-sec flex align-items-center justify-content-between">
          <div className="ftrleftoverlaybox"></div>
          <div className="socialiconsbox flex justify-content-center">
            <Link to="/" className="socialIcon ml-3 mr-3">
              <img src={facebook} alt="facebook" />
            </Link>
            <Link to="/" className="socialIcon ml-3 mr-3">
              <img src={twitter} alt="twitter" />
            </Link>
            <Link to="/" className="socialIcon ml-3 mr-3">
              <img src={insta} alt="instagram" />
            </Link>
          </div>
          <div className="ftrrightoverlaybox"></div>
        </div>
      </div>
    </>
  );
}
