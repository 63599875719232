/** @format */
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import React, { Component } from "react";
import { useTranslation } from "react-i18next";
// import React, { useRef } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';


//page componets
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';

//import css
// import "./home.scss";

// images
import bannerimage from "../../assets/images/structure/privacy-policy-baneer-img.png";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <>
  
    <Header />
      <div className="main-content-wrapper">
        <section className="static-pages-banner">
          <img src={bannerimage} alt="Privacy Policy" className="bannerimage" />
        </section>
        <section className="static-content">
          <div className="container">
            <h1 className="h1 mb-5">{t("Privacy Policy")}</h1>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("Introduction")}</h4>
              <p className="p3 mb-3">{t("privacy_introduction_1")}</p>
              <p className="p3 mb-3">{t("privacy_introduction_2")}</p>
              <ul className="pl-4">
                <li className="mb-1">{t("privacy_introduction_list_1")}</li>
                <li className="mb-1">{t("privacy_introduction_list_2")}</li>
                <li className="mb-1">{t("privacy_introduction_list_3")}</li>
              </ul>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("privacy_data_collection")}</h4>
              <p className="p3 mb-3">{t("privacy_data_collection_paragraph")}</p>
              <ul className="pl-4">
                <li className="mb-1">{t("privacy_data_collection_list_1")}</li>
                <li className="mb-1">{t("privacy_data_collection_list_2")}</li>
                <li className="mb-1"> {t("privacy_data_collection_list_3")}</li>
              </ul>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("privacy_collect_information")}</h4>
              <ul className="pl-4">
                <li className="mb-1">{t("privacy_collect_information_list_1")}</li>
                <li className="mb-1">{t("privacy_collect_information_list_2")}</li>
                <li className="mb-1">{t("privacy_collect_information_list_3")}</li>
              </ul>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("privacy_the_information_we_collect")}</h4>
              <ul className="pl-4">
                <li className="mb-1">{t("privacy_the_information_we_collect_list_1")}</li>
                <li className="mb-1">{t("privacy_the_information_we_collect_list_2")}</li>
                <li className="mb-1">{t("privacy_the_information_we_collect_list_3")}</li>
              </ul>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("privacy_the_technology_we_use")}</h4>
              <ul className="pl-4">
                <li className="mb-1">{t("privacy_the_technology_we_use_list_1")}</li>
                <li className="mb-1">{t("privacy_the_technology_we_use_list_2")}</li>
                <li className="mb-1">{t("privacy_the_technology_we_use_list_3")}</li>
              </ul>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("privacy_we_use_information")}</h4>
              <p className="p3 mb-3">{t("privacy_we_use_information_paragraph")}</p>
              <ul className="pl-4">
                <li className="mb-1">{t("privacy_we_use_information_list_1")}</li>
                <li className="mb-1">{t("privacy_we_use_information_list_2")}</li>
                <li className="mb-1">{t("privacy_we_use_information_list_3")}</li>
                <li className="mb-1">{t("privacy_we_use_information_list_4")}</li>
                <li className="mb-1">{t("privacy_we_use_information_list_5")}</li>
                <li className="mb-1">{t("privacy_we_use_information_list_6")}</li>
                <li className="mb-1">{t("privacy_we_use_information_list_7")}</li>
                <li className="mb-1">{t("privacy_we_use_information_list_8")}</li>
                <li className="mb-1">{t("privacy_we_use_information_list_9")}</li>
              </ul>
              <p className="p3 mb-0 mt-3">{t("privacy_we_also_use_information_paragraph_2")}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("privacy_discloser")}</h4>
              <p className="p3 mb-3">{t("privacy_discloser_paragraph_1")}</p>
              <p className="p3 mb-3">{t("privacy_discloser_paragraph_2")}</p>
            </div>
          </div>
        </section>
      </div>
    <Footer />
    </>
  );
};

export default PrivacyPolicy;
