/** @format */
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import React, { Component } from "react";
import { useTranslation } from "react-i18next";
// import React, { useRef } from 'react';
import { Accordion, AccordionTab } from "primereact/accordion";

import locationIcon from "../../assets/images/structure/location-icon.svg";
import doubleArrow from "../../assets/images/structure/double-arrow.svg";
import emailIcon from "../../assets/images/structure/email-icon.svg";
import contactIcon from "../../assets/images/structure/contact-icon.svg";
//page componets
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";

//import css
// import "./home.scss";

// images
import bannerimage from "../../assets/images/structure/faq-banner-img.png";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

const DeleteAccountReq = () => {
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      const phoneRegex = /^\d{8}$/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (
        !data?.name ||
        !data?.phone ||
        (data?.phone && !phoneRegex.test(data?.phone)) ||
        (data?.email && !emailRegex.test(data?.email)) ||
        !data?.reason ||
        (data?.reason && data?.reason?.length < 10)
      ) {
        let errors = {};
        if (!data?.name) {
          errors.name = "Please enter your name";
        }
        if (!data?.phone) {
          errors.phone = "Please enter your phone number";
        }
        if (data?.phone && !phoneRegex.test(data?.phone)) {
          errors.phone = "Please enter a valid mobile number";
        }
        if (data?.email && !emailRegex.test(data?.email)) {
          errors.email = "Please enter a valid email";
        }
        if (!data?.reason) {
          errors.reason = "Please enter the reason";
        }
        if (data?.reason && data?.reason?.length < 10) {
          errors.reason = "Please enter proper reason";
        }
        setError(errors);
        return;
      }
      setError({});
      setLoading(true);
      if (!data?.phone?.includes("+974")) {
        data.phone = `+974${data?.phone}`;
      }
      let lang = localStorage.getItem("i18nextLng");
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL + "delete-account-req",
        data,
        {
          headers: {
            language: lang,
          },
        }
      );
      toast.success(res?.data?.message);
      setLoading(false);
      setData({
        name: "",
        email: "",
        phone: "",
        reason: "",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (typeof error?.response?.data?.message === "string") {
        toast.error(error?.response?.data?.message);
        return;
      }
      toast.error("Internal server error");
    }
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };

  return (
    <>
      <Header />
      <ToastContainer />
      <div className="main-content-wrapper">
        <section className="static-pages-banner">
          <img src={bannerimage} alt="Privacy Policy" className="bannerimage" />
        </section>
        <section className="static-content">
          <div className="container">
            <div className="contactUsBox">
              <div className="grid">
                <div className="lg:col-12 md:col-12 sm:col-12 col-12 ml-auto">
                  <h1>{t("Delete Account Request")}</h1>
                  <div>
                    <div className="formField">
                      <InputText
                        type="text"
                        className="p-inputtext-lg"
                        placeholder={t("name")}
                        name="name"
                        maxLength={20}
                        onChange={handleChange}
                        value={data?.name}
                      />
                      <p className="errorMsg">{t(error?.name)}</p>
                    </div>
                    <div className="formField">
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">+974</span>
                        <InputText
                          type="number"
                          className="p-inputtext-lg"
                          placeholder={t("mobileNumber")}
                          onChange={handleChange}
                          name="phone"
                          value={data?.phone}
                        />
                      </div>
                      <p className="errorMsg">{t(error?.phone)}</p>
                    </div>
                    <div className="formField">
                      <InputText
                        type="text"
                        className="p-inputtext-lg"
                        placeholder={t("email")}
                        onChange={handleChange}
                        name="email"
                        value={data?.email}
                      />
                      <p className="errorMsg">{t(error?.email)}</p>
                    </div>
                    <div className="formField">
                      <InputTextarea
                        autoResize
                        rows={5}
                        cols={30}
                        placeholder={t("Reason")}
                        onChange={handleChange}
                        name="reason"
                        maxLength={500}
                        value={data?.reason}
                      />
                      <div
                        style={{ marginLeft: "94%" }}
                        className=" d-flex justify-content-end"
                      >
                        <p>
                          {data?.reason?.length ? data?.reason?.length : 0}/500
                        </p>
                      </div>
                      <p className="errorMsg">{t(error?.reason)}</p>
                    </div>
                    <div>
                      <Button
                        className="btn w-100"
                        onClick={handleSubmit}
                        style={{ width: "10%" }}
                        disabled={loading}
                      >
                        {loading ? t("Requesting...") : t("Request")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default DeleteAccountReq;
