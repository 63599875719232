/** @format */
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import React, { Component } from "react";
import { useTranslation } from "react-i18next";
// import React, { useRef } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';


//page componets
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';

//import css
// import "./home.scss";

// images
import bannerimage from "../../assets/images/structure/terms-condition-banner-img.png";

const TermsCondition = () => {
  const { t } = useTranslation();

  return (
    <>
  
    <Header />
      <div className="main-content-wrapper">
        <section className="static-pages-banner">
          <img src={bannerimage} alt="Privacy Policy" className="bannerimage" />
        </section>
        <section className="static-content">
          <div className="container">
            <h1 className="h1 mb-5">{t('termsAndCondition')}</h1>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("Introduction")}</h4>
              <p className="p3 mb-3">{t("t&c_welcome_text_1")}</p>
              <p className="p3 mb-3">{t("t&c_welcome_text_2")}</p>
              <p className="p3 mb-3">{t("t&c_welcome_text_3")}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("Accounts")}</h4>
              <p className="p3 mb-3">{t("t&c_account_section")}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("Intellectual Property")}</h4>
              <p className="p3 mb-3">{t("t&c_intellectual_property_section")}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("Links To Other Web Sites")}</h4>
              <p className="p3 mb-3">{t("t&c_link_other_website_1")}</p>
              <p className="p3 mb-3">{t("t&c_link_other_website_2")}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("Termination")}</h4>
              <p className="p3 mb-3">{t("t&c_termination_section")}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("Indemnification")}</h4>
              <p className="p3 mb-3">{t("t&c_indemnification_section")}</p>
            </div>

            <div className="mb-5">
              <h4 className="h4 mb-3">{t("Limitation Of Liability")}</h4>
              <p className="p3 mb-3">{t("t&c_limitation_of_liability_section")}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("Disclaimer")}</h4>
              <p className="p3 mb-3">{t("t&c_disclaimer_section")}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("Governing Law")}</h4>
              <p className="p3 mb-3">{t("t&c_governing_law_1")}</p>
              <p className="p3 mb-3">{t("t&c_governing_law_2")}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("Changes")}</h4>
              <p className="p3 mb-3">{t("t&c_changes_section")}</p>
            </div>
            <div className="mb-5">
              <h4 className="h4 mb-3">{t("Contact Us")}</h4>
              <p className="p3 mb-3">{t("t&c_contact_us_section")}</p>
            </div>
          </div>
        </section>
      </div>
    <Footer />
    </>
  );
};

export default TermsCondition;
